.k-top {
  background-color: transparent;
}

.k-menu {
  content-visibility: auto;
  contain-intrinsic-height: 100px;

  position: fixed;

  height: 50px;
  width: 100%;

  z-index: 10;

  .k-menu-wrapper {
    display: flex;
    width: 100%;
    height: 50px;
    font-size: 14px;

    > * {
      flex: 1 1 100%;
      // display: flex;
      // justify-content: space-between;
      // padding-bottom: 24px;
    }

    justify-content: space-between;
    background-color: var(--kengle-color-light);
    align-items: center;
    color: black;
  }
  .k-menu-kengle {
    flex: 1 0 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    // height: 100%;

    background-color: var(--kengle-color-light);
    color: #fff;

    img {
      max-height: 40px;
    }
  }
  .k-menu-item {
    //color: var(--kengle-color-light);
    color: #272525;
    text-align: center;
    text-decoration: none;
    // outline: 1px dashed #787878;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    line-height: normal;
    letter-spacing: normal;
    font-family: SuisseIntl-Medium;
    border-bottom: 4px solid transparent;
    border-top: 4px solid transparent;

    &.active-link {
      border-bottom-color: var(--kengle-color-black);
    }
  }
  .rotated {
    transform: rotate(90deg);
    transform-origin: bottom;
    display: inline-block;
  }
}

.testus {
  position: relative;
  display: block;
  // float: left;
  width: 100%;
}

.k-feed-backdrop {
  display: none; // not done yet
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 200px;
  border: 1px solid transparent;
  z-index: 10;
  background-color: var(--kengle-color-light);
  top: 79px;
}

.kengle-logo {
  width: 77px;
  margin-top: 4px;
  margin-left: 16px;
}
