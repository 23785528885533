.kengle-rating-feedback {
  background-color: var(--kengle-color-light);
  color: var(--kengle-color-black);
  border: 1px solid transparent;
  border-radius: 20px 20px 0 0;
  position: fixed;
  box-shadow: 0px 1px 14px 0px #000000;
  padding: 1.5rem 0;
  height: 100vh;
  width: 100%;
  z-index: 100;
  top: 20vh;
}

.kengle-rating-header {
  height: 84px;
  margin: 0 27px 44px 24px;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.kengle-rating-star-body {
  width: 75%;
  height: 100%;
  margin: 0 auto;
  padding: 0 0 63px;
  text-align: center;
}

.kengle-rating-txt-line {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;

  .bad-rating-txt {
    float: left;
  }

  .good-rating-txt {
    float: right;
  }
}

.kengle-rating-stars {
  margin: 0 50px;
}

.kengle-rating-btn {
  padding: 20px 20px 20px;
  border-radius: 28px;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.37);
  border: solid 2px #272525;
  background-color: #f1eee5;
  width: 100%;
  color: var(--kengle-color-black);
  font-size: 18px;
  text-align: center;
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  .kengle-rating-btn {
    margin: 60px 0px 0 0;
  }
}

@supports not (-webkit-touch-callout: none) {
  /* CSS for other than iOS devices */
  .kengle-rating-btn {
    margin: 120px 0px 0 0;
  }
}
