.ScrollToTop-Btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 56px;
  height: 56px;
  font-size: 100px;
  background: #f1eee5;
  color: black;
  opacity: 0.8;
  cursor: pointer;
  transform: rotate(-90deg);
  padding: 0px 14px;
  border-radius: 40px;
}
