.k-tagline {
  text-align: center;
  font-size: 18px;
  color: #f1eee5;

  svg {
    path {
      fill: var(--kengle-color-light);
    }
  }

  //margin-top: 38px;
  margin-bottom: 1rem;

  .feed {
    font-weight: normal;
  }

  .skills {
    margin-left: 0.25em;
    font-family: Georgia, "Times New Roman", Times, serif;
  }
}

.kengle-img-background {
  background-repeat: no-repeat;
  background-size: cover;
  // margin-top: -24px;

  .customer-background {
    height: 150px;
    margin-top: 50px;
    margin-bottom: -50px;

    .k-top {
      background-image: url("../content/img/Feed_toppbilde-maanen_darker_w_gradient.jpg");
      background-repeat: round;
      height: 243px;
      width: 100%;
      object-fit: contain;
    }
  }
}

.kengle-slogo {
  margin-top: 15%;
}
