.news-article-container {
  background-color: #fff;
  // margin-top: -50px;
  z-index: 20;

  .container-article-video {
    margin-top: 0;
    height: 46em;
  }

  .close-article-btn {
    padding: 19px 27px;
    opacity: 0.8;
    background-color: rgba(39, 37, 37, 0.45);
    color: white;
    border-radius: 50px;
    border: 2px rgb(255, 255, 255);
    position: absolute;
    z-index: 30;
    display: block;
    left: 78%;
    top: 2%;
  }

  .article-news-header {
    font-size: 32px;
    background-color: #000;
    font-style: italic;
    line-height: 1.03;
    color: #fff;
    padding: 24px 10px 16px 10px;
  }

  .short-story-wrapper {
    font-size: 13px;
    color: #d24b4b;
    padding: 1em 1em 0em 1em;

    .short-story {
      float: left;
    }

    .time-spent-reading {
      float: right;
    }

    .short-story-icon {
      float: right;
      margin-right: 0.5em;
    }
  }

  .ingress {
    color: #000;
    margin-bottom: -20px;
    font-weight: bold;
    padding: 2.5em 1em 1em;
  }

  .body {
    height: 100%;
    color: #000;
    padding: 2.5em 1em 1em;
  }
}

.news-article-footer {
  color: #000;

  svg {
    float: left;
    display: flex;
    position: absolute;
    left: 0.5em;
    color: #c00000;
    margin-top: 1.2em;
  }

  .footer-section-main {
    padding: 1em 3em 2em 3em;

    .footer-section-main-text {
      .footer-section-main-number {
        text-decoration: underline;
        font-size: 20px;
      }
    }
  }

  .footer-section-wrapper {
    background-color: #fff;

    .footer-section {
      padding: 0.2em 3em 0em 3em;
      background-color: #fff;
      width: 100%;

      .footer-phone {
        background-color: #c00000;
        color: #fff;
        padding: 3px;
      }

      .footer-section-text {
        font-size: 20px;
      }

      .footer-phone-number {
        display: block;
        text-decoration: underline;
        font-size: 18px;
        padding-bottom: 1em;
      }
    }
  }
}

.article-image {
  height: 100%;
  max-width: 100%;
  object-fit: cover;
  position: relative;
  width: 100%;
}
