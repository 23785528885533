.carousel-root.inline-gallery {
  position: relative;
  z-index: 20;
  overflow: scroll;
}

.carousel .slider-wrapper.inline-gallery {
  height: 422px;
}

.carousel .slide img.inline-gallery {
  height: 422px;
}

.carousel img.inline-gallery {
  height: 422px;
}

.article-slide {
  display: flex;
  height: 422px;

  img {
    object-fit: cover;
  }
}

.carousel-root {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20;
  overflow: hidden;
}

.carousel .control-dots .dot {
  //border-radius: 0% !important;
  width: 12px;
  height: 12px;
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  .carousel .control-dots {
    bottom: 20%;
  }
}

@supports not (-webkit-touch-callout: none) {
  /* CSS for other than iOS devices */
  .carousel .control-dots {
    bottom: 10%;
  }
}

.carousel .slider-wrapper {
  height: 100% !important;
}

.carousel.carousel-slider .control-arrow {
  display: none;
}

.my-slide {
  display: flex;
  height: 100vh;

  img {
    object-fit: cover;
  }
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  .img-content-txt {
    background: #f1eee5 !important;
    line-height: 26px !important;
    font-size: 24px !important;
    color: black !important;
    bottom: 26% !important;
  }
}

@supports not (-webkit-touch-callout: none) {
  /* CSS for other than iOS devices */
  .img-content-txt {
    background: #f1eee5 !important;
    line-height: 26px !important;
    font-size: 24px !important;
    color: black !important;
    bottom: 16% !important;
  }
}

.gallery-next-btn {
  padding: 21px 41px 17px;
  border-radius: 28px;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.37);
  border: solid 2px #fff;
  background-color: rgba(0, 0, 0, 0.13);
  color: #fff;
  position: fixed;
  left: 35%;
  bottom: 5%;
  display: block;
}

.close-gallery-btn {
  padding: 19px 27px;
  opacity: 0.8;
  background-color: rgb(39 37 37 / 45%);
  color: white;
  border-radius: 50px;
  border: 2px rgb(255, 255, 255);
  position: fixed;
  z-index: 25;
  display: block;
  left: 78%;
  top: 2%;
}

.close-img {
  width: 375px;
  height: 844px;
  padding: 32px 0 0;
  font-size: 2rem;
}

.carousel .slide .legend {
  opacity: 0.9;
}
