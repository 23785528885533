@mixin videoButton() {
  height: 48px;
  width: 48px;
  border: 2px solid white;
  border-color: rgb(255 255 255 / 80%);
  color: rgb(255 255 255 / 80%);
  background-color: hsl(0deg 0% 0% / 14%);
  border-radius: 50%;
}

.video-js {
  // .vjs-control-bar {
  //   position: relative;
  // }

  /* Change all text and icon colors in the player. */
  .vjs-matrix.video-js {
    color: var(--kengle-color-medium);
    // position: fixed;
    // right: 0;
    // bottom: 0;
    // min-width: 100%;
    // min-height: 100%;
    // transform: translateX(calc((100% - 100vw) / 2));
  }

  .vjs-poster {
    background-color: #dedbd0;
    background-size: cover;
  }

  /* Change the border of the big play button. */
  .vjs-big-play-button {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    border: none;

    border-radius: 50%;
    background-color: rgb(0 0 0 / 54%);

    height: 86px;
    width: 86px;

    background-image: url("../../content/svg/video_icon.svg");
    background-repeat: no-repeat;
    background-size: 46px;
    background-position: 55% calc(50% - 0px);

    .vjs-icon-placeholder {
      display: none;
      // display: flex;
      // align-items: center;
      // justify-content: center;

      &::before {
        font-size: 64px;
        position: static;
      }
    }
  }

  /* Change the color of various "bars". */
  // .vjs-matrix .vjs-volume-level,
  // .vjs-matrix .vjs-play-progress,
  // .vjs-matrix .vjs-slider-bar {
  //   background: var(--kengle-color-red);
  // }

  // .video-js .vjs-control-bar {
  //   background-color: transparent;
  // }

  .vjs-control-bar {
    position: static;
    background-color: transparent;
  }

  .vjs-control {
  }

  // play-knapp
  .vjs-play-control {
    @include videoButton();

    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 8%;

    .vjs-icon-placeholder:before {
      position: relative;
      font-size: 30px;
      line-height: 1;
    }
  }

  .vjs-volume-panel {
    @include videoButton();

    border-color: transparent;

    position: absolute;
    bottom: 8%;
    right: 5%;

    align-items: center;
    justify-content: center;

    .vjs-mute-control {
      height: auto;
      width: auto;
    }

    .vjs-icon-placeholder:before {
      position: relative;
      font-size: 30px;
      line-height: 1;
    }
  }

  .vjs-progress-control {
    position: absolute;
    width: 100%;
    height: unset;
    bottom: calc(8% + 70px);

    .vjs-slider {
      background-color: rgb(255 255 255 / 20%);
    }

    .vjs-load-progress {
      background-color: rgb(255 255 255 / 20%);

      div {
        background-color: rgb(255 255 255 / 10%);
      }
    }
  }

  .vjs-progress-holder {
    flex: auto;
    transition: all 0.2s;
    height: 0.7em;
  }

  .vjs-play-progress:before {
    font-size: 1.2em;
    position: absolute;
    right: -0.5em;
    top: -0.233333em;
    z-index: 1;
  }

  // Hide picture in picture
  .vjs-picture-in-picture-control {
    display: none;
  }

  // when fullscreen
  // &.vjs-fullscreen {
  .vjs-fullscreen-control {
    position: absolute;

    right: 2vh;
    top: 2vw;
    width: 30px;
    height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.67);

    .vjs-icon-placeholder {
      font-size: 18px;

      &::before {
        position: relative;
      }
    }

    // }
  }

  &.vjs-fullscreen {
    .vjs-fullscreen-control {
      top: 5vh;
      right: 5vw;
      width: 86px;
      height: 86px;

      .vjs-icon-placeholder {
        font-size: 30px;
      }
    }
  }
}
