.kengle-footer {
  background-color: var(--kengle-color-black);
  color: var(--kengle-color-light);

  content-visibility: auto;
  contain-intrinsic-height: 400px;

  padding: 1rem;
}

.footer-svg {
  svg {
    max-width: 188px;
    > path {
      fill: var(--kengle-color-light);
      // opacity: 0.8;
    }
  }
}

.kengle-footer-img {
  margin: 0 auto;
  width: 235px;
}

.footer-gif {
  max-width: 100%;
}

.kengle-copyright {
  text-align: center;
  padding-top: 1.5rem;
  color: #fff;
  font-size: 10px;
  opacity: 0.5;
}

.kengle-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 110px;
  height: 50px;
  padding: 14px;
  margin: 0 auto;

  background-color: var(--kengle-color-red);

  svg > path {
    fill: var(--kengle-color-light);
  }
}
