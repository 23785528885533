.cookie-wrapper {
  z-index: 99;
  position: fixed;
  bottom: 0px;
  color: #fff;
  background-color: #272525;
  max-width: 820px;
  box-shadow: rgb(0 0 0 / 100%) 0px 22px 70px 4px;

  .cookie-content {
    text-align: center;
    padding: 1em;

    .button-group {
      margin-top: 1em;
      display: flex;
      justify-content: space-around;

      button {
        flex-basis: 45%;
        height: 3em;
        border-radius: 20px;
        border: 1px;
        color: #000;
        background-color: var(--kengle-color-light);
        cursor: pointer;
      }
    }
  }
}
