@import "./components/ArticleNews/ArticleNews.scss";
@import "./components/Cookies/Cookies.scss";

@font-face {
  font-family: "SuisseIntl-Medium";
  src: url("/fonts/SuisseIntl-Medium.otf") format("opentype");
}
@font-face {
  font-family: "SuisseIntl-BoldItalic";
  src: url("/fonts/SuisseIntl-BoldItalic.otf") format("opentype");
}

@font-face {
  font-family: "SuisseWorks-Medium";
  src: url("/fonts/SuisseWorks-Medium.otf") format("opentype");
}

@media screen and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

.kengleLabel {
  background-color: #c00000;
  color: white;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  text-align: center;
  width: 100%;
  height: 100%;
}

#kengle-root {
  max-width: 820px;
  margin: 0 auto;

  #transition-root {
    background-color: var(--kengle-color-light);
    // float: left;
    width: 100%;
    display: block;
  }
}

:root {
  --kengle-color-red: #c00000;
  --kengle-color-black: #272525;
  --kengle-color-light: #f1eee5;
  --kengle-color-medium: #dedbd0;
  --kengle-color-white: #ffffff;
}

.fadeIn {
  animation: 0.2s fadeIn forwards;
}

.fadeOut {
  animation: 0.15s fadeOut forwards;
}

@keyframes fadeIn {
  from {
    // opacity: 0;
    // transform: translate(0, -20px);
  }
  to {
    // opacity: 1;
    // transform: translate(0px, 0px);
  }
}

@keyframes fadeOut {
  from {
    // opacity: 1;
    // transform: translate(0px, 0px);
  }
  to {
    // transform: translate(0, -20px);
    // opacity: 0;
  }
}
